import { FC, useCallback, useMemo, useState } from 'react'
import Image from 'next/image'
import tw from 'tailwind-styled-components'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import {
  MarketSection,
  SectionDesc,
  ForestCard,
  Hero,
  ForosLVHero,
  FooterLanding,
  SellingSections,
  BackgroundImage,
  AuthModal,
  ForestValueInquiryModal,
  Button,
} from '@foros-fe/core'
import { BaseMarketAuctionsDocument, usePaginatedBaseMarketAuctionQuery } from '@foros-fe/graphql'
import { fetchSSRQuery } from '@/utils/apolloClient'
import { domains } from '@foros-fe/core/config'
import BuyIcon from '@foros-fe/assets/icons/buy-green.png'
import GrowIcon from '@foros-fe/assets/icons/grow-green.png'
import SellIcon from '@foros-fe/assets/icons/sell-green.png'
import BackgroundForest from '@foros-fe/assets/images/background-forest.png'
import Portfolio from '@foros-fe/assets/images/portfolio.png'
import Partner1 from '@foros-fe/assets/images/partners/1.png'
import Partner2 from '@foros-fe/assets/images/partners/2.png'
import Partner3 from '@foros-fe/assets/images/partners/3.png'
import Partner4 from '@foros-fe/assets/images/partners/4.png'
import Partner5 from '@foros-fe/assets/images/partners/5.png'
import Partner6 from '@foros-fe/assets/images/partners/6.png'
import Partner7 from '@foros-fe/assets/images/partners/7.png'
import ForestBook from '@foros-fe/assets/images/forest-book.png'

const ContentContainer = tw.div<{ $row?: boolean }>`
  container
  relative
  flex
  ${(p) => (p.$row ? 'flex-row' : 'flex-col')}
`
const Partner = tw.div`flex m-4`

const queryVariables = { first: 3 }

function MarketingSection({ onClick }: { onClick(): void }) {
  const { t } = useTranslation('landing')
  const marketSections = useMemo(
    () => [
      {
        title: t('buy'),
        icon: BuyIcon,
        info: t('buyMarket.content'),
        bulletPoints: [
          t('buyMarket.bulletPoint1'),
          t('buyMarket.bulletPoint2'),
          t('buyMarket.bulletPoint3'),
        ],
      },
      {
        title: t('grow'),
        icon: GrowIcon,
        info: t('growMarket.content'),
        bulletPoints: [
          t('growMarket.bulletPoint1'),
          t('growMarket.bulletPoint2'),
          t('growMarket.bulletPoint3'),
        ],
      },
      {
        title: t('sell'),
        icon: SellIcon,
        info: t('sellMarket.content'),
        bulletPoints: [
          t('sellMarket.bulletPoint1'),
          t('sellMarket.bulletPoint2'),
          t('sellMarket.bulletPoint3'),
        ],
      },
    ],
    [t]
  )

  return (
    <ContentContainer className='pt-16 md:py-24'>
      <SectionDesc
        className='mb-16 md:mb-20 flex-1 items-center text-center'
        title={t('gainKnowledgeTitle')}
        info={t('gainKnowledgeContent')}
        onClick={onClick}
      />
      <div className='flex flex-col md:flex-row justify-between text-left '>
        {marketSections.map((s, i) => (
          <MarketSection key={`mc-${i}`} {...s} />
        ))}
      </div>
    </ContentContainer>
  )
}

function AuctionsSection({ onClick }: { onClick(): void }) {
  const { t } = useTranslation('landing')
  const { data } = usePaginatedBaseMarketAuctionQuery(queryVariables)

  return (
    <div className='relative flex-1'>
      <BackgroundImage src={BackgroundForest} />
      <div className='absolute z-1 w-full h-full bg-gradient-to-b from-white via-transparent to-transparent'></div>
      <ContentContainer className='z-2 text-center pt-14 md:py-20'>
        <SectionDesc
          className='items-center mb-20'
          icon={BuyIcon}
          sectionTitle={t('buyingSection.title')}
          title={t('buyingSection.heading')}
          info={t('buyingSection.content')}
          onClick={onClick}
        />
        <div className='flex flex-1 flex-col md:flex-row justify-between -mx-4'>
          {data?.marketAuctions.nodes.map((auction) => (
            <ForestCard key={auction?.id} {...auction} />
          ))}
        </div>
      </ContentContainer>
    </div>
  )
}

const ForestBookSection: FC<{ onClick(): void }> = ({ onClick }) => {
  const { t } = useTranslation('landing')

  return domains.current.forestBookEnabled ? (
    <div className='container mt-8 flex flex-col-reverse md:flex-row space-x-0 md:space-x-8 space-y-4 space-y-reverse md:space-y-0'>
      <Image src={ForestBook} width={160} height={213.33} objectFit='contain' />
      <div className='flex flex-col flex-1 space-y-4 md:items-start'>
        <p className='text-3xl font-bold'>{t('forestBook.title')}</p>
        <p className='text-lg'>{t('forestBook.description')}</p>
        <Button onClick={onClick} value='open-inquiry-modal-landing' title={t('forestBook.CTA')} />
        <p>{t('forestBook.actionDescription')}</p>
      </div>
    </div>
  ) : null
}

function ForosLVLanding() {
  const { t } = useTranslation('landing')
  const [authOpen, setAuthOpen] = useState(false)
  const [inquiryModalOpen, setInquiryModalOpen] = useState(false)
  const openAuthModal = useCallback(() => setAuthOpen(true), [setAuthOpen])

  return (
    <div>
      <main>
        <ForosLVHero />
        <ForestBookSection onClick={() => setInquiryModalOpen(true)} />
        <AuctionsSection onClick={openAuthModal} />
        <MarketingSection onClick={openAuthModal} />
        <div className='flex flex-col border-t-2 py-8 space-y-8'>
          <p className='font-semibold text-2xl md:text-4xl text-black font-poppins leading-snug text-center'>
            {t('partners')}
          </p>
          <div className='flex flex-wrap justify-evenly items-center'>
            <Partner>
              <Image src={Partner1} />
            </Partner>
            <Partner>
              <Image src={Partner2} />
            </Partner>
            <Partner>
              <Image src={Partner3} />
            </Partner>
            <Partner>
              <Image src={Partner4} />
            </Partner>
            <Partner>
              <Image src={Partner5} />
            </Partner>
            <Partner>
              <Image src={Partner6} />
            </Partner>
            <Partner>
              <Image src={Partner7} />
            </Partner>
          </div>
        </div>
        <FooterLanding />
        <AuthModal registration open={authOpen} onClose={() => setAuthOpen(false)} />
        <ForestValueInquiryModal
          open={inquiryModalOpen}
          onClose={() => setInquiryModalOpen(false)}
        />
      </main>
    </div>
  )
}

function ForosLanding() {
  const { t } = useTranslation('landing')
  const [authOpen, setAuthOpen] = useState(false)
  const [inquiryModalOpen, setInquiryModalOpen] = useState(false)
  const openAuthModal = useCallback(() => setAuthOpen(true), [setAuthOpen])
  const openInquiryModal = useCallback(() => setInquiryModalOpen(true), [setInquiryModalOpen])

  return (
    <div>
      <main>
        <Hero onPrimaryClick={openInquiryModal} />
        <ForestBookSection onClick={openInquiryModal} />
        <MarketingSection onClick={openAuthModal} />
        <AuctionsSection onClick={openAuthModal} />
        <div className='w-full box-border border-b border-gray-300'>
          <ContentContainer $row className='py-14 md:py-40'>
            <div className='flex-1 text-center md:text-left'>
              <SectionDesc
                className='items-center md:items-start mr-0 md:mr-6 max-w-xl'
                icon={GrowIcon}
                sectionTitle={t('growingSection.title')}
                title={t('growingSection.heading')}
                info={t('growingSection.content')}
                onClick={openAuthModal}
              />
            </div>
            <div className='hidden md:flex flex-1 justify-end'>
              <Image objectFit='contain' src={Portfolio} width={560} height={377} />
            </div>
          </ContentContainer>
        </div>
        <SellingSections onClick={openAuthModal} />
        <FooterLanding />
        <AuthModal registration open={authOpen} onClose={() => setAuthOpen(false)} />
        <ForestValueInquiryModal
          open={inquiryModalOpen}
          onClose={() => setInquiryModalOpen(false)}
        />
      </main>
    </div>
  )
}

const Landing = () => {
  switch (domains.current.name) {
    case 'foros.lv':
    case 'miskoaukcionas.lt':
      return ForosLVLanding

    default:
      return ForosLanding
  }
}

export default Landing()

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const [apolloProps, translationProps] = await Promise.all([
    fetchSSRQuery({
      query: BaseMarketAuctionsDocument,
      variables: queryVariables,
    }),
    serverSideTranslations(locale!, [
      'common',
      'landing',
      'enum',
      'auctions',
      'inquiries',
      'authentication',
    ]),
  ])

  return {
    props: {
      ssr: true,
      ...apolloProps,
      ...translationProps,
    },
    revalidate: 60,
  }
}
