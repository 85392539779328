import React, { FC, useMemo } from 'react'
import tw from 'tailwind-styled-components'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import Link from 'next/link'
import Image from 'next/image'
import { NavBar } from './Navigation/NavBar'
import { BackgroundImage } from './BackgroundImage'
import { Button } from './Button'
import { Card } from './Card'
import { PriceCalculator as PriceCalculatorForm } from './Forms/PriceCalculator'
import { useDeviceMediaQuery } from '../hooks'
import { clientOnlyComponent, formatMoney } from '../utils'
import { domains, theme } from '../config'
import { CurrencyCode } from '@foros-fe/graphql'
import HeroImg from '@foros-fe/assets/images/hero.png'
import HeroMobileImg from '@foros-fe/assets/images/hero-mobile.png'
import MALogo from '@foros-fe/assets/images/ma-logo-white.png'

const Container = tw.div`md:min-h-[796px] min-h-screen flex flex-col relative bg-green-700`
const Heading = tw.h1`text-lg md:leading-normal font-poppins md:text-4xl font-bold`
const MainContainer = tw.div`
  container
  z-30
  flex-1
  flex
  flex-col
  justify-start
  md:justify-around
  text-white
  text-center
  pt-24
  pb-12
  space-y-8
`
const GradientContainer = tw.div`
  hidden
  md:block
  z-20
  absolute
  bg-gradient-to-r
  left-0
  right-0
  bottom-0
  top-[68px]
  from-green-700
  via-transparent
  to-green-700
`
const HeroContainer = tw.div`z-10 absolute left-0 right-0 bottom-0 top-[68px]`
const InvertedButton = styled(Button)`
  border-color: ${theme.colors.white};
  color: ${theme.colors.white};
`

const HeroStatistic: FC<{ value: string; text: string }> = ({ value, text }) => (
  <div className='flex flex-col items-center mx-4'>
    <div className='font-bold text-4xl mb-4'>{value}</div>
    <div className='text-center'>{text}</div>
  </div>
)

const HeroBackground = clientOnlyComponent(() => {
  const { mobile } = useDeviceMediaQuery()

  return (
    <HeroContainer>
      <BackgroundImage src={mobile ? HeroMobileImg : HeroImg} priority />
    </HeroContainer>
  )
})

export const ForosLVHero: FC = () => {
  const { t } = useTranslation('landing')
  const statistics = useMemo(
    () => [
      {
        value: '1,147+',
        text: t('totalDeals'),
      },
      {
        value: '11 319 ha',
        text: t('totalArea', {
          totalSumOfAllEndedAuctions: formatMoney(
            {
              amount: '26547748',
              __typename: 'MoneyObject',
              currency: { __typename: 'Currency', isoCode: CurrencyCode.Eur },
            },
            {
              minimumFractionDigits: 0,
            }
          ),
        }),
      },
      {
        value: '12 648',
        text: t('totalBids'),
      },
      {
        value: '€17,599/ha',
        text: t('highestBid'),
      },
    ],
    [t]
  )
  const isMA = domains.current.name === 'miskoaukcionas.lt'

  return (
    <Container>
      <NavBar />
      <GradientContainer />
      <HeroBackground />
      <MainContainer>
        <div className='flex flex-1 flex-col md:flex-row space-x-0 md:space-x-8 space-y-8 md:space-y-0'>
          <div className='flex flex-col items-center md:items-start flex-1 space-y-8 md:text-left'>
            {isMA ? <Image src={MALogo} width={320} height={224.906} /> : null}
            <Heading>{isMA ? t('topHeadingMA') : t('topHeading')}</Heading>
            <p>{t('subheading')}</p>
            {isMA ? <p>{t('partOfForos')}</p> : null}
            <Link href='/auctions'>
              <InvertedButton title={t('secondaryCTA')} value='hero-secondary' outline />
            </Link>
          </div>
          <Card className='md:flex-1 text-black md:self-start'>
            <PriceCalculatorForm />
          </Card>
        </div>
        <div className='flex justify-between flex-col md:flex-row space-y-4 md:space-y-0'>
          {statistics.map((stat) => (
            <HeroStatistic key={stat.value} {...stat} />
          ))}
        </div>
      </MainContainer>
    </Container>
  )
}
