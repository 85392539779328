import React, { FC, useCallback } from 'react'
import tw from 'tailwind-styled-components'
import { useForm, Controller } from 'react-hook-form'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { Modal } from './Modal'
import { TextInput } from './Inputs/TextInput'
import { PhoneInput } from './Inputs/PhoneInput'
import { Button } from './Button'
import { Form } from './Forms/Form'
import { SvgIcon } from './SvgIcon'
import {
  useUpdateRoughPriceInquiryContactsMutation,
  UpdateRoughPriceInquiryContactsInput,
} from '@foros-fe/graphql'
import { BackgroundImage } from './BackgroundImage'
import CalculatorBackground from '@foros-fe/assets/images/calculator-modal.png'
import Logo from '@foros-fe/assets/icons/foros-logo-white.svg'
import { getErrorTranslations } from '../utils'

type Props = {
  open: boolean
  onClose(): void
  inquiryId: string
}
type FormData = UpdateRoughPriceInquiryContactsInput

const Text = tw.p`font-bold mb-4 text-gray-800`
const InputsContainer = tw.div`flex flex-col space-y-6`
const BgImgContainer = tw.div`
  hidden
  md:flex
  absolute
  left-[-300px]
  bottom-[-1px]
  top-[-1px]
  w-[305px]
  items-center
  justify-center
  rounded-l-lg
  overflow-hidden
`

export const RoughPriceInquiryModal: FC<Props> = ({ open, onClose, inquiryId: id }) => {
  const { t } = useTranslation(['inquiries', 'common'])
  const form = useForm<FormData>({ defaultValues: { phoneNumber: '' } })
  const router = useRouter()
  const [updateRoughPriceInquiryContacts] = useUpdateRoughPriceInquiryContactsMutation()
  const onSubmit = useCallback(
    async (input: FormData) => {
      const { data } = await updateRoughPriceInquiryContacts({
        variables: { input: { ...input, id } },
      })

      const error = data?.updateRoughPriceInquiryContacts?.error
      if (error) {
        if (error.email) {
          setError(
            'email',
            getErrorTranslations('UpdateRoughPriceInquiryContactsEmailError', error.email)
          )
        }
        if (error.phoneNumber) {
          setError(
            'phoneNumber',
            getErrorTranslations(
              'UpdateRoughPriceInquiryContactsPhoneNumberError',
              error.phoneNumber
            )
          )
        }
      } else {
        router.push(`/price-inquiries/${id}`)
      }
    },
    [router, id]
  )

  const { register, control, setError } = form

  return (
    <Modal open={open} onClose={onClose} cardClassname='md:ml-[300px] text-center'>
      <Text className='text-2xl'>{t('roughPrice.calculatedTitle')}</Text>
      <Text className='text-lg'>{t('roughPrice.calculatedSubtitle')}</Text>
      <Text className='font-medium'>{t('roughPrice.enterContacts')}</Text>
      <Form {...form} onSubmit={onSubmit}>
        <InputsContainer>
          <TextInput label={t('common:email')} {...register('email', { required: true })} />
          <Controller
            control={control}
            name='phoneNumber'
            rules={{ required: true }}
            render={({ field }) => <PhoneInput label={t('common:phoneNumber')} {...field} />}
          />
          <Button type='submit' value='rough-price-inquiry' title={t('roughPrice.getPriceNow')} />
        </InputsContainer>
      </Form>
      <BgImgContainer>
        <BackgroundImage src={CalculatorBackground} />
        <SvgIcon src={Logo} width={164} height={40} className='relative' />
      </BgImgContainer>
    </Modal>
  )
}
