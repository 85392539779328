import React, { FC, HTMLAttributes } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import tw from 'tailwind-styled-components'
import { useTranslation } from 'next-i18next'
import { BaseAuctionFragment, useBaseAuctionChangedSubscription } from '@foros-fe/graphql'
import { clientOnlyModule, formatMoney, endTimestamp } from '../utils'
import { Button } from './Button'
import { ActiveTag } from './ActiveTag'
import { Countdown } from './Countdown'
import Pin from '@foros-fe/assets/icons/pin.png'

const AuctionCardMap = clientOnlyModule(
  () => import('@foros-fe/core/components/Maps/AuctionCardMap')
)

type Props = Omit<HTMLAttributes<HTMLButtonElement>, 'title'> & BaseAuctionFragment

const Container = tw.div`bg-white overflow-hidden text-left flex flex-1 mx-4 md:max-w-sm flex-col rounded-lg mb-8 md:mb-0`
const Line = tw.div`flex flex-row`
const PriceContainer = tw.div`flex-1 flex flex-col`
const PriceTitle = tw.p`text-gray-700 text-xs uppercase mb-2 whitespace-nowrap`
const Price = tw.p`text-black font-poppins text-lg`
const LiveTag = tw(ActiveTag)`absolute left-3 top-3`

export const ForestCard: FC<Props> = ({
  title,
  address,
  startingBid,
  endTime,
  cadastralPlots,
  highestBidObject,
  id,
}) => {
  const { t } = useTranslation()
  useBaseAuctionChangedSubscription({ variables: { id } })

  return (
    <Container>
      <div className='w-full h-48 relative'>
        <AuctionCardMap cadastralPlots={cadastralPlots} />
        <LiveTag active title='LIVE' />
      </div>
      <div className='flex flex-1 flex-col p-4'>
        <p className='font-poppins font-bold text-lg mb-4'>{title}</p>
        <Line className='items-center mb-3 mt-auto'>
          <div className='flex mr-3 min-w-[24px]'>
            <Image src={Pin} width={24} height={24} />
          </div>
          <p className='text-gray-700 text-sm'>{address}</p>
        </Line>
        <div>
          <Countdown endTime={endTime} showTimestamp={false} />
        </div>
        <Line className='pl-9 mb-5 text-gray-700 text-sm'>{endTimestamp(endTime)}</Line>
        <Line className='mb-3'>
          <PriceContainer>
            <PriceTitle>{t('startingPrice')}</PriceTitle>
            <Price>{formatMoney(startingBid)}</Price>
          </PriceContainer>
          <PriceContainer>
            <PriceTitle>{t('highestBid')}</PriceTitle>
            <Price className='font-semibold'>{formatMoney(highestBidObject?.price)}</Price>
          </PriceContainer>
        </Line>
        <Link href={`/auctions/${id}`}>
          <Button title={t('learnMore')} value='forest-card-nav-to-auction' />
        </Link>
      </div>
    </Container>
  )
}
