import React, { FC, useCallback } from 'react'
import tw from 'tailwind-styled-components'
import Link from 'next/link'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { Modal } from './Modal'
import { TextInput } from './Inputs/TextInput'
import { PhoneInput } from './Inputs/PhoneInput'
import { Button } from './Button'
import { Form } from './Forms/Form'
import { CheckBox } from './Inputs/CheckBox'
import { SignUpWithEmailInput, useCreateForestValueInquiryMutation } from '@foros-fe/graphql'
import { setFormFieldsErrorTranslations } from '../utils'
import { useLogin } from '../hooks'

type Props = {
  open: boolean
  onClose(): void
}
type FormData = Pick<
  SignUpWithEmailInput,
  | 'email'
  | 'password'
  | 'phoneNumber'
  | 'cadastralNumber'
  | 'termsAndConditionsAccepted'
  | 'marketingConditionsAccepted'
>

const Title = tw.p`font-medium text-2xl mb-6 text-gray-800`
const InputsContainer = tw.div`flex flex-col space-y-6`
const NavLink = tw.span`text-primary-500 hover:underline cursor-pointer`

export const ForestValueInquiryModal: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation(['inquiries', 'common', 'authentication'])
  const form = useForm<FormData>()
  const [createForestValueInquiryMutation] = useCreateForestValueInquiryMutation()
  const login = useLogin()
  const onSubmit = useCallback(
    async (input: FormData) => {
      const { data } = await createForestValueInquiryMutation({ variables: { input } })

      const error = data?.signUpWithEmail?.error
      const token = data?.signUpWithEmail?.token

      if (error) {
        return setFormFieldsErrorTranslations(setError, error, {
          email: 'SignUpWithEmailEmailError',
          password: 'SignUpWithEmailPasswordError',
          phoneNumber: 'SignUpWithEmailPhoneNumberError',
          termsAndConditionsAccepted: 'SignUpWithEmailTermsAndConditionsAcceptedError',
        })
      }

      login(token)
    },
    [login]
  )

  const { register, control, setError } = form

  return (
    <Modal open={open} onClose={onClose} cardClassname='text-center'>
      <Title>{t('forestValue.title')}</Title>
      <Form {...form} onSubmit={onSubmit}>
        <InputsContainer>
          <TextInput label={t('common:email')} {...register('email', { required: true })} />
          <TextInput
            label={t('authentication:password')}
            type='password'
            {...register('password', { required: true })}
          />
          <Controller
            control={control}
            name='phoneNumber'
            render={({ field }) => <PhoneInput label={t('common:phoneNumber')} {...field} />}
          />
          <TextInput label={t('common:enterCadastralNumber')} {...register('cadastralNumber')} />
          <CheckBox
            label={
              <span>
                {t('common:iAgreeWith')}{' '}
                <Link href='/rules'>
                  <NavLink>{t('common:nav.rules')}</NavLink>
                </Link>{' '}
                {t('common:connectors.and')}{' '}
                <Link href='/privacy-policy'>
                  <NavLink>{t('common:nav.privacyPolicy')}</NavLink>
                </Link>
              </span>
            }
            {...register('termsAndConditionsAccepted')}
          />
          <CheckBox
            label={t('common:marketingAgreement')}
            {...register('marketingConditionsAccepted')}
          />
          <Button type='submit' value='forest-value-inquiry' title={t('forestValue.register')} />
        </InputsContainer>
      </Form>
    </Modal>
  )
}
